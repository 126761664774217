export default {
  error: {
    wrong: 'Something went wrong. Please try again.',
    order: {
      no_changes: 'Hm... It looks like you did not make any changes. Please update company information, add or remove domains and then continue.',
      exist_open_order: 'There is an open order associated with the company. When the order is complete, you can place a new order.',
      order_pay_error_1: 'Please check your card details and try again.',
      order_pay_error_2: 'We could not charge your card. Maybe you need to verify transaction with your bank? Or you can try a different card.',
      order_pay_error_3: 'Your card was declined. It\'s OK, cards can be tricky sometimes. You can select another payment methods or continue with the order without payment right now. You will receive an invoice and somebody from our team will reach out to you right away.',
    },
    auth: {
      reset_link_expired: "Hm, something went wrong. Please try again. If it doesn't work, please send us a quick email %email% and let us know what happened.",
      login_error: 'Invalid Email or Password.',
      email_exist: 'Something went wrong. You might already have an account. Please try password reset feature.<br /> If you need further assistance, please contact us at support@dmcanow.io.',
      token_expired: 'Token expired or not valid',
      error_401: "User isn't logged in",
      error_405: "User isn't logged in",
    },
    sender: {
      not_found_senders: 'No senders found.',
      not_found_senders_description: 'No senders have been connected to your account.',
    },
    notice: {
      no_emails: 'Please, select an email the notice will be send to',
      subject: 'Please, select subject',
      error_add_link: 'The URL is already added to the notice.',
    },
  },
  success: {
    xero_link: {
      updated: 'Xero contact id linked.',
    },
    company: {
      biling_updated: 'Company billing information was updated!',
      role_updated: 'Role was changed!',
    },
    domain: {
      deleted: 'Domain email was deleted!',
      updated: 'Domain email was updated!',
    },
    sender: {
      updated: 'Sender was updated.',
    },
    user: {
      updated: 'User name was updated!',
      status_update: 'User status updated',
      status_error: 'Something went wrong...',
    },
    order: {
      updated: 'Order was updated!',
      paid: 'Order was paid!',
      deleted: 'Order was deleted.',
    },
    auth: {
      password_update: 'Password was successfully updated!',
    },
    payment: {
      payment_method_changed: 'Payment method was successfully updated!',
    },
    notice: {
      update_status: 'Notice status was successfully updated!',
      update: 'Notice was successfully updated!',
      send: 'Copyright Notice was successfully send!',
      deleted: 'Notice was deleted.',
    },
  },
  ui: {
    buttons: {
      search_senders: 'Search Senders',
    },
    order: {
      'DMCA Registration': [
        'Are you sure you want to delete the order and all related company data?',
        'This action <u>cannot be undone</u>. If you delete this order all information related to this order and <u>all company related data</u> will be permanently deleted!'
      ],
      'DMCA Amendment': [
        'Are you sure you want to delete the order?',
        'This action <u>cannot be undone</u>. If you delete this order <u>all information related to this order</u> will be permanently deleted and any action will be undone!'
      ],
      'DMCA Removal': [
        'Are you sure you want to delete the order?',
        'This action <u>cannot be undone</u>. If you delete this order <u>all information related to this order</u> will be permanently deleted and any action will be undone!'
      ],
      'DMCA Renewal': [
        'Are you sure you want to delete the order?',
        'This action <u>cannot be undone</u>. If you delete this order <u>all information related to this order</u> will be permanently deleted and any action will be undone!'
      ],
    },
  },
};